import { faEnvelopeOpen, faImage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { updateDetailsThunk, changeStripeConnectThunk, removeBankThunk, getProfileThunk } from "../../features/auth/authThunk";
import { authSelector } from "../../features/auth/authSelector";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useStripe } from '@stripe/react-stripe-js';
import Loader from "../../Loader";
import moment from "moment";

function StepOne(props) {
  const { userData, token } = useSelector(authSelector);
  const dispatch = useDispatch();
  const stripe = useStripe();
  const [isLoading, setLoading] = useState(false);

  const states = [
    { "code": "FR-ARA", "name": "Auvergne-Rhône-Alpes" },
    { "code": "FR-BFC", "name": "Bourgogne-Franche-Comté" },
    { "code": "FR-BRE", "name": "Bretagne" },
    { "code": "FR-CVL", "name": "Centre-Val de Loire" },
    { "code": "FR-COR", "name": "Corse" },
    { "code": "FR-GES", "name": "Grand Est" },
    { "code": "FR-HDF", "name": "Hauts-de-France" },
    { "code": "FR-IDF", "name": "Île-de-France" },
    { "code": "FR-NOR", "name": "Normandie" },
    { "code": "FR-NAQ", "name": "Nouvelle-Aquitaine" },
    { "code": "FR-OCC", "name": "Occitanie" },
    { "code": "FR-PDL", "name": "Pays de la Loire" },
    { "code": "FR-PAC", "name": "Provence-Alpes-Côte d'Azur" },
    { "code": "FR-GUA", "name": "Guadeloupe" },
    { "code": "FR-GUF", "name": "Guyane" },
    { "code": "FR-MQ", "name": "Martinique" },
    { "code": "FR-RE", "name": "La Réunion" },
    { "code": "FR-MAY", "name": "Mayotte" }
  ];

  const [configObject, setConfigObject] = useState({
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  const investProfileStep1Schema = Yup.object({
    account_holder_name: Yup.string().required("Required!"),
    account_number: Yup.string().required("Required!"),
    address: Yup.string().required("Required!"),
    city: Yup.string().required("Required!"),
    state: Yup.string().required("Required!"),
    postal_code: Yup.number().required("Required!"),
    stripe_id_number: Yup.string().required("Required!"),
    identity_document_front: Yup.string().required("Required!"),
    identity_document_back: Yup.string().required("Required!"),
    //address_document: Yup.string().required("Required!"),
    bank_account_ownership_verification: Yup.string().required("Required!"),
  });

  const formik = useFormik({
    initialValues: {
      account_holder_name: "",
      account_number: "",
      //routing_number: "",
      address: "",
      city: "",
      state: "",
      postal_code: "",
      stripe_id_number: "",
      identity_document_front: "",
      identity_document_back: "",
      //address_document: "",
      bank_account_ownership_verification: "",
    },
    validationSchema: investProfileStep1Schema,
    onSubmit: async (values) => {

      try {
        const birthDate = moment(userData.dob, 'YYYY-MM-DD');
        const currentAge = moment().diff(birthDate, 'years');

        if (!userData?.dob || userData?.dob == '' || currentAge < 13) {
          toast.error('Please complete basic details first and age is more than 13 years');

        } else {
          setLoading(true);
          let userFormData = new FormData();
          userFormData.append("account_holder_name", values.account_holder_name);
          userFormData.append("account_number", values.account_number);
          userFormData.append("address", values.address);
          userFormData.append("city", values.city);
          userFormData.append("state", values.state);
          userFormData.append("country", 'FR');
          userFormData.append("postal_code", values.postal_code);
          userFormData.append("stripe_id_number", values.stripe_id_number);
          userFormData.append("identityDocumentFront", values.identity_document_front);
          userFormData.append("identityDocumentBack", values.identity_document_back);
          //userFormData.append("addressDocument", values.address_document);
          userFormData.append("bankAccountDocment", values.bank_account_ownership_verification);

          let formData = new FormData();
          formData.append("purpose", "identity_document");
          formData.append("file", values.identity_document_front);
          let frontDoc = await axios.post(
            `https://files.stripe.com/v1/files`,
            formData,
            {
              headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );

          if (frontDoc?.status === 200) {
            userFormData.append("frontIdentityId", frontDoc?.data?.id);
          } else {
            toast.error('Identity Document Front is not uploaded');
          }

          let formData1 = new FormData();
          formData1.append("purpose", "identity_document");
          formData1.append("file", values.identity_document_back);
          let backDoc = await axios.post(
            `https://files.stripe.com/v1/files`,
            formData1,
            {
              headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          if (backDoc?.status === 200) {
            userFormData.append("backIdentityId", backDoc?.data?.id);
          } else {
            toast.error('Identity Document Back is not uploaded');
          }

          let formData2 = new FormData();
          formData2.append("purpose", "account_requirement");
          formData2.append("file", values.bank_account_ownership_verification);
          let ownerVerificationDoc = await axios.post(
            `https://files.stripe.com/v1/files`,
            formData2,
            {
              headers: {
                "Authorization": `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}`,
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          );
          if (ownerVerificationDoc?.status === 200) {
            userFormData.append("bankOwnerIdentityId", ownerVerificationDoc?.data?.id);
          } else {
            toast.error('Owner verification document is not uploaded');
          }

          let nameArr = userData.full_name.split(' ');
          let dobArr = userData.dob.split('-');

          const accountResult = await stripe.createToken('account', {
            business_type: 'individual',
            individual: {
              first_name: nameArr[0],
              last_name: nameArr.length > 1 ? nameArr[1] : nameArr[0],
              email: userData.email,
              phone: userData.phone_number,
              id_number: values.stripe_id_number,
              dob: {
                year: dobArr.length > 0 ? dobArr[0] : '1990',
                month: dobArr.length > 1 ? dobArr[1] : '01',
                day: dobArr.length > 2 ? dobArr[2] : '01',
              },
              address: {
                line1: values.address,
                city: values.city,
                state: values.state,
                country: 'FR',
                postal_code: values.postal_code
              }
            },
            tos_shown_and_accepted: true,
          });

          if (accountResult) {
            userFormData.append("account_token", accountResult.token.id);
          }

          const personResult = await stripe.createToken('person', {
            person: {
              first_name: nameArr[0],
              last_name: nameArr.length > 1 ? nameArr[1] : nameArr[0],
              address: {
                line1: values.address,
                city: values.city,
                state: values.state,
                country: 'FR',
                postal_code: values.postal_code
              }
            },
          });

          if (personResult) {
            userFormData.append("person_token", personResult.token.id);
          }

          if (backDoc?.status === 200 && frontDoc?.status === 200 && ownerVerificationDoc?.status === 200) {
            const detailResult = await stripe.createToken('account', {
              individual: {
                verification: {
                  document: {
                    front: frontDoc?.data?.id, // URL of the new front image
                    back: backDoc?.data?.id, // URL of the new back image
                  },
                },
              },
              documents: {
                bank_account_ownership_verification: {
                  files: [ownerVerificationDoc?.data?.id]
                }
              },
              tos_shown_and_accepted: true,
            });

            if (detailResult) {
              userFormData.append("person_detail_token", detailResult.token.id);
            }
          }

          if (accountResult && personResult) {
            const response = await dispatch(changeStripeConnectThunk({ ...configObject, body: userFormData }));

            if (response?.payload?.status === 200) {
              toast.success(response?.payload?.data?.message);
              props.onNextStep();
            } else {
              toast.error(response?.payload?.response?.data?.message);
            }
          } else {
            toast.error('Document is not uploaded');
          }
        }
        // const resp2 = await dispatch(
        //   updateDetailsThunk({ ...configObject, body: values })
        // );
        // if (resp2?.payload?.status === 200) {
        //   props.onNextStep();
        // }
      } catch (error) {
        console.log(error)
        toast.error(error.message);
        toast.error("Some problem exist, Please try again");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleIdentityFront = (e) => {
    formik.setFieldValue("identity_document_front", e.target.files[0]);
  };

  const handleIdentityBack = (e) => {
    formik.setFieldValue("identity_document_back", e.target.files[0]);
  };

  const handleBankVerification = (e) => {
    formik.setFieldValue("bank_account_ownership_verification", e.target.files[0]);
  };

  // const handleAddressDoc = (e) => {
  //   formik.setFieldValue("address_document", e.target.files[0]);
  // };

  const removeBankAccout = async () => {
    setLoading(true);
    const resp = await dispatch(removeBankThunk({
      ...configObject, body: {
        account_holder_name: null,
        account_number: null,
        routing_number: null,
        url: null,
        stripe_account_id: null,
        stripe_bank_id: null,
        stripe_person_id: null,
        ssn_last_4: null,
        stripe_id_number: null,
        identity_document_front: null,
        identity_document_bank: null,
        //address_document: null,
        bank_account_ownership_verification: null
      }
    }));
    if (resp?.payload?.status === 200) {
      await dispatch(getProfileThunk({ ...configObject }));
      toast.success('Document and Bank details removed successfully');
    } else {
      toast.error(resp?.payload?.response?.data?.message);
    }
    setLoading(false);
  }

  // console.log(verificationDocs, 'verificationDocs');
  //console.log(addressDocs, 'addressDocs')
  //console.log(formik.errors)

  return (
    <>
      <div className="mt-4">
        {
          userData?.stripe_account_id ? <>
            <Form.Group className="mb-3">
              <Form.Label>Account holder name: </Form.Label>
              {' ' + userData?.account_holder_name}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account number: </Form.Label>
              {' ' + userData?.account_number}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Account address: </Form.Label>
              {' ' + userData?.address + ', ' + userData?.city + ', ' + userData?.state + ', ' + userData?.country + ', ' + userData?.postal_code}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Government ID number: </Form.Label>
              {' ' + userData?.stripe_id_number}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Identity Front Side: </Form.Label>
              {
                <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.identity_document_front}`}> {userData?.identity_document_front}</a>
              }
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Identity Back Side: </Form.Label>
              {
                <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.identity_document_back}`}> {userData?.identity_document_back}</a>
              }
            </Form.Group>
            {/* <Form.Group className="mb-3">
              <Form.Label>Address Document: </Form.Label>
              {
                <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.address_document}`}> {userData?.address_document}</a>
              }
            </Form.Group> */}
            <Form.Group className="mb-3">
              <Form.Label>Bank Account Ownership Document: </Form.Label>
              {
                <a href={`${process.env.REACT_APP_SERVER_URL}/uploads/users/original/${userData?.bank_account_ownership_verification}`}> {userData?.bank_account_ownership_verification}</a>
              }
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Document Verified: </Form.Label>
              {userData?.documentVerified ? ' Yes' : ' No'}
            </Form.Group>
            {
              isLoading ? <Loader /> :
                <>
                  <Button variant="danger" className="px-4" onClick={removeBankAccout}>
                    Remove Document and Bank Details
                  </Button>
                  <Button variant="primary" className="px-4 mx-4" onClick={() => props.onNextStep()}>
                    Next Step
                  </Button>
                </>
            }

          </> : <Form onSubmit={formik.handleSubmit}>
            <Form.Label className="text-dark">
              Upload Verification Documents
            </Form.Label>
            <Form.Group
              as={Col}
              md="12"
              controlId="identity_document_front"
              className="mb-3"
            >
              <div className="btn_upload p-4">
                <Form.Label className="text-dark">
                  <div className="mb-2">
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  Upload Identity Front Side - Government-issued ID
                </Form.Label>
                <Form.Control
                  type="file"
                  className="input-img"
                  name="identity_document_front"
                  accept="image/*"
                  onChange={(e) => handleIdentityFront(e)}
                />
              </div>
              {
                formik.values.identity_document_front?.name
              }

              <span style={{ color: "red" }}>
                {formik.errors.identity_document_front ? (
                  <div>{formik.errors.identity_document_front} </div>
                ) : null}{" "}
              </span>
            </Form.Group>

            <Form.Group
              as={Col}
              md="12"
              controlId="identity_document_back"
              className="mb-3"
            >
              <div className="btn_upload p-4">
                <Form.Label className="text-dark">
                  <div className="mb-2">
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  Upload Identity Back Side - Government-issued ID
                </Form.Label>
                <Form.Control
                  type="file"
                  className="input-img"
                  name="identity_document_back"
                  accept="image/*"
                  onChange={(e) => handleIdentityBack(e)}
                />
              </div>
              {
                formik.values.identity_document_back?.name
              }

              <span style={{ color: "red" }}>
                {formik.errors.identity_document_back ? (
                  <div>{formik.errors.identity_document_back} </div>
                ) : null}{" "}
              </span>
            </Form.Group>

            {/* <Form.Group
              as={Col}
              md="12"
              controlId="address_document"
              className="mb-3"
            >
              <div className="btn_upload p-4">
                <Form.Label className="text-dark">
                  <div className="mb-2">
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  Upload Address Document - Government-issued ID
                </Form.Label>
                <Form.Control
                  type="file"
                  className="input-img"
                  name="address_document"
                  accept="image/*"
                  onChange={(e) => handleAddressDoc(e)}
                />
              </div>
              {
                formik.values.address_document?.name
              }

              <span style={{ color: "red" }}>
                {formik.errors.address_document ? (
                  <div>{formik.errors.address_document} </div>
                ) : null}{" "}
              </span>
            </Form.Group> */}

            <Form.Group
              as={Col}
              md="12"
              controlId="bank_account_ownership_verification"
              className="mb-3"
            >
              <div className="btn_upload p-4">
                <Form.Label className="text-dark">
                  <div className="mb-2">
                    <FontAwesomeIcon icon={faImage} />
                  </div>
                  Bank Account Ownership Document
                </Form.Label>
                <Form.Control
                  type="file"
                  className="input-img"
                  name="bank_account_ownership_verification"
                  accept="image/*"
                  onChange={(e) => handleBankVerification(e)}
                />
              </div>
              {
                formik.values.bank_account_ownership_verification?.name
              }

              <span style={{ color: "red" }}>
                {formik.errors.bank_account_ownership_verification ? (
                  <div>{formik.errors.bank_account_ownership_verification} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Government issue Identity number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Government issue ID number"
                name="stripe_id_number"
                onChange={formik.handleChange}
                value={formik.values.stripe_id_number}
              />
              <span style={{ color: "red" }}>
                {formik.errors.stripe_id_number ? (
                  <div>{formik.errors.stripe_id_number} </div>
                ) : null}{" "}
              </span>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Account holder name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Account holder name"
                name="account_holder_name"
                onChange={formik.handleChange}
                value={formik.values.account_holder_name}
              />
              <span style={{ color: "red" }}>
                {formik.errors.account_holder_name ? (
                  <div>{formik.errors.account_holder_name} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Account number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Account number"
                name="account_number"
                onChange={formik.handleChange}
                value={formik.values.account_number}
              />
              <span style={{ color: "red" }}>
                {formik.errors.account_number ? (
                  <div>{formik.errors.account_number} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                name="address"
                onChange={formik.handleChange}
                value={formik.values.address}
              />
              <span style={{ color: "red" }}>
                {formik.errors.address ? (
                  <div>{formik.errors.address} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                placeholder="City"
                name="city"
                onChange={formik.handleChange}
                value={formik.values.city}
              />
              <span style={{ color: "red" }}>
                {formik.errors.city ? (
                  <div>{formik.errors.city} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>State</Form.Label>
              <Form.Select
                onChange={formik.handleChange}
                value={formik.values.state}
                name="state"
              >
                <option value="">Please Select</option>
                {
                  states.map((item) => {
                    return (<option value={item?.code} key={item?.code}>{item?.name}</option>)
                  })
                }

              </Form.Select>
              <span style={{ color: "red" }}>
                {formik.errors.state ? (
                  <div>{formik.errors.state} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Country</Form.Label>
              <Form.Control
                type="text"
                placeholder="France"
                name="country"
                onChange={formik.handleChange}
                disabled
              />
              <span style={{ color: "red" }}>
                {formik.errors.country ? (
                  <div>{formik.errors.country} </div>
                ) : null}{" "}
              </span>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Postal code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Postal Code"
                name="postal_code"
                onChange={formik.handleChange}
                value={formik.values.postal_code}
              />
              <span style={{ color: "red" }}>
                {formik.errors.postal_code ? (
                  <div>{formik.errors.postal_code} </div>
                ) : null}{" "}
              </span>
            </Form.Group>

            {
              isLoading ? <Loader /> : <Button type="submit" className="login-btn px-4 w-100 my-4">
                Save
              </Button>
            }
          </Form>
        }

      </div>
    </>
  );
}

export default StepOne;
